<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">About Us</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                About Us
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-7 py-md-10">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div>
              <div class="section-title mb-4">
                <h2 class="text-danger pl-0 order-1">Wellcome to</h2> <img style="width: 20%" src="assets/img/logo-school.png" alt="logo">
              </div>
              <p class="text-dark font-size-17 mb-4">i 玩錢是一個兒童財商（FQ）教育平台，希望藉由遊戲化的趣味問答演練以及生活化的 親子互動學習單，打造孩子的金融素養，建立正確的金錢觀念，並以具體行動實踐學習及早培養孩子的理財知識。</p>
              <p class="text-dark font-size-17 mb-4">有人問我，你幹嘛一定要做親子理財，吃力不討好，是有㸃吃飽力不討好，畢竟是教 孩子金融素養，難度比教中文、數學難，但是還是要一路走下去，因為我知道財商（FQ, Financial Intelligence Quotient 財務智慧商數)在每個人生中所扮演的角色。</p>
              <p class="text-danger font-size-17 mb-4">IQ(智商)、EQ(情商)相信大家都不陌生，但FQ(財商)卻鮮少聽聞，因為小學課本裡沒有教。所謂的財商，是指一個人在個人投資，理財方面的智慧和能力指數，簡單說就是你的「理財智商」(理財能力)。</p>
              <p class="text-dark font-size-17 mb-4">很多人家長會擔心跟孩子談「錢」，並且誤解「財商教育」，其實我們要教孩子的是「認識金錢的價值」、「你用錢的態度」、最後才是「投資理財的技能」。所以我們 不是直接教孩子投資買股票，而是正確金錢觀念，以及個人理財的知識與能力。</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-info text-white pt-9 pb-8" style="background-image: url(assets/img/background/avator-bg.png);">
      <div class="container">
        <div class="section-title justify-content-center mb-4 mb-md-8">
          <span class="shape shape-left bg-danger"></span>
          <h2 class="text-white">團隊介紹</h2>
          <span class="shape shape-right bg-danger"></span>
        </div>
        <div class="row">
          <div class="col-12 mb-7 mb-md-8 mb-lg-9">
            <div class="media media-avator-view flex-column flex-sm-row">
              <a class="media-img mb-5 mb-sm-0 mr-md-5 mr-lg-6 rounded-circle shadow-sm" href="teachers-details.html">
                <img class="rounded-circle" src="assets/img/avator/avator-img1.jpg" alt="avator-img1.jpg">
              </a>
              <div class="media-body">
                <a class="font-size-20 font-weight-medium d-inline-block mb-1 text-white" href="teachers-details.html">馬哈（Maja）</a>
                <span class="text-muted font-size-15 mb-1 d-block">創辦人</span>
                <p class="text-white font-size-17 mb-4">創辦人馬哈（Maja）也是 moneybar 網站創辦人，曾任職 Yahoo 其摩股市/理財頻道， 同時也是一位 14 歲孩子的媽媽，貼身教孩子五年理財知識，具有財務金融知識也具備財經系統軟體的開發經驗，財經資歷超過 20 年，著有「馬哈親子理財 10 堂課：存錢、花錢、賺錢，魔法便利貼的金錢教養」一書。</p>
                <p class="text-white font-size-17 mb-4">i 玩錢團隊都具備相關金融證照，且近年來舉辦小小巴菲特親子理財營，以實戰遊戲方 式培養孩子的金融素養，並親自帶領孩子到台灣證券交易所及股票博物館參訪，相關 營隊及課程吸引許多父母的關注與重視。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'about',
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
